import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/styles.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 支持某些条件下隐藏元素
import 'element-ui/lib/theme-chalk/display.css';
// 修改element-ui主题
import '@/assets/element-variables.scss'

// 导入阿里iconfont矢量图标
import './assets/icon/iconfont.js';
import './assets/icon/iconfont.css';

// 引入 swiper.css
import 'swiper/css/swiper.css';

// 引入全局变量
import global_ from '@/components/global.js';
Vue.prototype.GLOBAL = global_;

// i18
import VueI18n from 'vue-i18n'
import i18n from '@/lang/index'

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
  VueI18n,
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
