<template>
  <div class="bottom">
    <div class="bottomText">
      <div class="bottomBox">
        <div class="bottomBox_left" v-for="(item, index) in BottomList" :key="index">
          <h2>{{ item.headName }}</h2>
          <ul>
            <li v-for="(brand, brandIndex) in item.brands" :key="brandIndex" @click="skip(brand)">
              <i :class="brand.iconClass"></i>
              {{ brand.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="twoCode">
        <img src="@/assets/img/weixin.jpg" alt="img" />
        <p>{{ $t('ScanQRCodes') }}</p>
      </div>
    </div>
    <div
      class="record"
      onclick="window.location='https://beian.miit.gov.cn/'"
      style="cursor: pointer"
    >
      <img
        src="https://www.cartier.cn/img/icp_icon1.png?v=2.1.6.51"
        style="width: 18px"
        alt="icon"
      />
      沪ICP备17049176号
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomView',
  data() {
    return {
      BottomList: [
        {
          headName: this.$t('AllBrands'),
          brands: [
            { name: this.$t('Rolex'), link: '/rolex/rolex' },
            { name: this.$t('TuDor'), link: '/tudor' },
            { name: this.$t('CarTier'), link: '/cartier' },
            { name: this.$t('IWC'), link: '/iwc' },
            { name: this.$t('Paneral'), link: '/paneral' },
          ],
        },
        {
          headName: this.$t('Service'),
          brands: [
            // { name: '常见问题', link: '' },
            { name: this.$t('Love'), link: '/love' },
            { name: this.$t('TermsForUsage'), link: '' },
            { name: this.$t('PrivacyPolicy'), link: '' },
            { name: this.$t('vipArea'), link: '/personage' },
          ],
        },
        {
          headName: this.$t('Upkeep'),
          brands: [
            { name: this.$t('Address'), link: '/Address', iconClass: 'el-icon-location-outline' },
          ],
        },
      ],
    };
  },
  methods: {
    // 跳转
    skip(item) {
      if (item.link !== '') {
        this.$router.push(item.link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  color: white;
  background: #a2a781;
  padding-bottom: 3%;
  @media (max-width: 768px) {
    padding-bottom: 5%;
  }
}

.record {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 2vw;
  }
}

.bottomText {
  padding: 3% 8% 0% 8%;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 768px) {
    padding: 3% 5% 0% 5%;
  }
}

.bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottomBox_left {
  display: flex;
  // margin-bottom: 8%;
  font-size: 1vw;

  h2 {
    width: 10vw;
    text-align: left;
    // margin-right: 1%;

    @media (max-width: 768px) {
      width: 15vw;
      font-size: 2.5vw;
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0; /* 移除默认的外边距 */

    li {
      margin: 0 10px;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        color: var(--main-color);
      }

      @media (max-width: 768px) {
        font-size: 2vw;
      }
    }
  }
}

.twoCode {
  font-size: 1vw;

  & > img {
    width: 10vw;
  }
  p {
    margin-top: 1vw;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.5vw;
  }
}
</style>
