<template>
  <div class="pageHead" v-if="content">
    <el-page-header @back="goBack" :content="content"></el-page-header>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
    };
  },
  mounted() {
    if (this.lang === 'en') {
      const element = document.querySelector('.el-page-header__title');
      if (element) {
        element.innerHTML = 'Return'; // 将内容修改为'return'
      }
    }
  },
  methods: {
    // 返回
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.pageHead ::v-deep {
  padding-left: 2%;
  .el-page-header__content {
    font-size: 0.9rem;
    color: #303133;
  }
}
</style>
