<template>
  <div class="home">
    <Naver />

    <div class="box">
      <!-- <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in imgList" :key="index">
            <div v-if="item.type === 'image'">
              <img :src="item.src" alt="轮播图" />
            </div>
            <div v-else-if="item.type === 'video'">
              <video :src="item.src" controls autoplay muted loop playsinline></video>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>

        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div> -->

      <Carousel ref="Carousel" />

      <h1>{{ $t('BrandRecommend') }}</h1>

      <div
        class="image-container"
        v-for="(item, index) in dataList"
        :key="index"
        @click="goIndex(item)"
      >
        <img v-if="item.seriesLogo" :src="item.seriesLogo[0]" :alt="$t('IMG')" />
        <img v-else src="" :alt="$t('IMG')" />
        <div class="text-overlay" :style="{ color: item.textColor ? item.textColor : 'white' }">
          <h2>{{ item.routeTile }}</h2>
          <p>{{ $t('BuyWatch') }}</p>
          <p>&gt;</p>
        </div>
      </div>
    </div>

    <Bottom />
  </div>
</template>

<script>
import Naver from '@/components/Naver.vue';
import Bottom from '@/components/Bottom.vue';
import { getRouteList } from '@/utils/api.js';
import Carousel from '@/components/Carousel.vue';

// import Swiper from 'swiper';

export default {
  name: 'HomeView',
  components: {
    Naver,
    Bottom,
    Carousel,
  },
  data() {
    return {
      imgList: [],
      dataList: [],
    };
  },
  mounted() {
    this.getRouteList();
  },
  methods: {
    getRouteList() {
      const data = {
        routeType: '表头',
        routeBrand: '冠亚首页',
      };
      getRouteList(data).then(res => {
        this.dataList = res.data.pageItems;
      });
    },
    // 跳转至各品牌首页
    goIndex(item) {
      this.$router.push(item.routeUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 80vh; /* 跟下面高度保持一致*/
  @media (max-width: 768px) {
    height: 70vh;
  }
}

/* 确保图片占满容器并保持纵横比 */
.swiper-slide img,
.swiper-slide video {
  width: 100vw;
  height: 80vh;
  object-fit: cover; /* 使图片覆盖容器 */
  @media (max-width: 768px) {
    height: 70vh;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #127749;
}

// 自定义箭头样式
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 2vw; // 更改箭头图标大小
}

.box ::v-deep {
  .swiper-pagination-bullet {
    width: 20px; /* 默认宽度（未选中） */
    height: 4px;
    background: #ccc; /* 默认颜色 */
    opacity: 1;
    border-radius: 2px;
    transition: width 0.3s, background 0.3s; /* 添加过渡效果 */
  }

  .swiper-pagination-bullet-active {
    width: 50px; /* 选中时宽度 */
    background: #127749; /* 选中时颜色 */
  }
}

h1 {
  margin: 5vh 0px;
  font-size: 3rem;
}

// 品牌推荐
.image-container {
  position: relative;
  display: inline-block;
  width: 100%; /* 使容器宽度适应父容器 */
  cursor: pointer;
  min-height: 200px;
}

.image-container img {
  width: 100%; /* 图片自适应宽度 */
  height: auto; /* 保持图片纵横比 */
  display: block;
}

.text-overlay {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: black;
  padding: 10px;
  border-radius: 5px;
  font-size: calc(1rem + 1vw); /* 响应式字体大小 */
  text-align: left;
}

.text-overlay .arrow {
  margin-left: 10px; /* 箭头与文本之间的间距 */
  font-size: calc(1rem + 1vw); /* 响应式箭头大小 */
  white-space: nowrap; /* 防止箭头换行 */
}

/* 响应式设计 - 小屏幕设备 */
@media (max-width: 768px) {
  .text-overlay {
    bottom: 3%;
    left: 3%;
    font-size: calc(0.8rem + 1vw);
  }

  .text-overlay .arrow {
    font-size: calc(0.8rem + 1vw);
    margin-left: 5px;
  }
}

/* 响应式设计 - 非常小屏幕设备 */
@media (max-width: 480px) {
  .text-overlay {
    bottom: 2%;
    left: 2%;
    font-size: calc(0.6rem + 1vw);
  }

  .text-overlay .arrow {
    font-size: calc(0.6rem + 1vw);
    margin-left: 3px;
  }
}
</style>
