//第一步导入axios
import axios from 'axios'
import { Loading } from 'element-ui'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import router from '@/router' // 引入 Vue Router

// 定义一个全局的加载实例
let loadingInstance

let requestCount = 0
//第二步 我们可以声明一个新的常量axios 我们可以配置一些基础 公共的路径配置   比如说baseURL timeout请求失败超时报错 withcookies...之类的东西
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL, //如果配置了环境变量就可以直接写/api,
  baseURL: '',
  withCredentials: true,
  timeout: 10000, //请求超时
})

//第三步 设置请求拦截
//新的常量axios service.拦截器.请求.使用===》 里头有两个参数 一个成功的回调函数  一个失败的回调函数
service.interceptors.request.use(
  config => {
    // 增加请求计数
    requestCount++
    const lang = localStorage.getItem('lang') || 'zh' // 默认值 'en'
    const loadingText = lang === 'en' ? 'Loading...' : '加载中...'

    loadingInstance = Loading.service({
      lock: true,
      text: loadingText,
      background: 'rgba(0, 0, 0, 0.7)',
    })

    // 从本地获取 lang 参数传递请求
    if (config.method === 'get' && config.params) {
      config.params.lang = lang
    } else if (config.method === 'post' && config.data) {
      config.data.lang = lang
    }

    // token
    // 判断接口是否以 /gy/ 开头，不传递 token
    if (!config.url.startsWith('/gy/')) {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
        // 写死跳过后端框架校验
        config.headers.Source = 'erpapp'
      }
    }

    return config
  },
  err => {
    throw new Error(err)
  }
)

//第四步 设置响应拦截
service.interceptors.response.use(
  response => {
    // 减少请求计数
    requestCount--
    if (loadingInstance) {
      // 只有当请求计数为 0 时才关闭加载动画
      if (requestCount === 0) {
        loadingInstance.close()
      }
    }
    const res = response.data

    if (res.status && res.status !== 200) {
      if (res.status === 401) {
        Message.error('登录超时，请重新登录')
      }
      return Promise.reject(res || 'error')
    } else {
      if (res.code === -100 && res.message === 'isNoRight') {
        console.log('登录过期,清除cookie和token')

        localStorage.getItem('lang') == 'zh'
          ? Message.error('登录过期，请重新登录')
          : Message.error('Login expired, please login again')
        router.push('/login')

        localStorage.removeItem('token')
        Cookies.remove('love')
      }

      return res
    }
  },
  err => {
    // 减少请求计数（即使请求失败也减少计数）
    requestCount--
    if (loadingInstance) {
      // 只有当请求计数为 0 时才关闭加载动画
      if (requestCount === 0) {
        loadingInstance.close()
      }
    }

    if (err.code === 'ECONNABORTED') {
      Message.error(this.$t('RequestTimedOut'))
    } else if (err.response) {
      const { status } = err.response
      if (status === 500) {
        Message.error(this.$t('ServerError'))
      }
    } else {
      Message.error(this.$t('RequestFailed'))
    }

    return Promise.reject(err)
  }
)
//导出  导出这个模块
export default service
