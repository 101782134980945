import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Login/Register.vue'),
  },
  {
    path: '/modify',
    name: 'modify',
    component: () => import('@/views/Login/Modify.vue'),
  },
  // 品牌首页
  // 因为劳力士的地址比较多，:pageUrl(.*)表示匹配任意字符，包括点
  {
    path: '/rolex/:pageUrl(.*)',
    name: 'rolex',
    meta: { brand: '劳力士' },
    component: () => import('@/views/Rolex/index.vue'),
  },

  {
    path: '/tudor',
    name: 'tudor',
    meta: { brand: '帝舵' },
    component: () => import('@/views/TuDor/index.vue'),
  },
  {
    path: '/cartier',
    name: 'cartier',
    meta: { brand: '卡地亚' },
    component: () => import('@/views/Cartier/index.vue'),
  },
  {
    path: '/iwc',
    name: 'iwc',
    meta: { brand: '万国' },
    component: () => import('@/views/IWC/index.vue'),
  },
  {
    path: '/paneral',
    name: 'paneral',
    meta: { brand: '沛纳海' },
    component: () => import('@/views/PANERAL/index.vue'),
  },
  // 系列展示页面
  {
    path: '/sort',
    name: 'sort',
    component: () => import('@/views/Sort.vue'),
  },
  // 详情页面
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/Detail.vue'),
  },
  // 会员
  {
    path: '/personage',
    name: 'personage',
    component: () => import('@/views/Personage.vue'),
  },
  // 愿望清单页面
  {
    path: '/love',
    name: 'love',
    component: () => import('@/views/Love.vue'),
  },
  // 地址页面
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/Address.vue'),
  },
  // 资讯页面
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/Information/Information.vue'),
  },
  {
    path: '/informationDetail', // 相对路径
    name: 'informationDetail',
    component: () => import('@/views/Information/InformationDetail.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  // base: '',
  routes,
});

// 控制每到一个页面，滚动条到达页面顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);

  if (to.path === '/personage') {
    // 获取本地存储的 token
    const token = localStorage.getItem('token');
    if (token) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});
export default router;
