import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 当前导航第几个模块
    activeIndex: parseInt(localStorage.getItem('activeIndex')) || 0,
    // 导航的品牌名
    brand: localStorage.getItem('brand') || null,
  },
  getters: {},
  mutations: {
    // 修改 activeIndex 的值
    setActiveIndex(state, index) {
      state.activeIndex = index;
      localStorage.setItem('activeIndex', index);
    },
    // 选中的品牌名
    setBand(state, brand) {
      state.brand = brand;
      localStorage.setItem('brand', brand);
    },
  },
  actions: {},
  modules: {},
});

