<template>
  <!-- 轮播图 -->
  <div class="swiperBox">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in ImgList" :key="index">
          <div v-if="item && item.type === 'image'">
            <img :src="item.src" alt="img" />
          </div>
          <div v-else-if="item && item.type === 'video'">
            <!-- <video class="sVideo" :src="item.src" controls autoplay muted loop playsinline></video> -->
            <video
              class="sVideo"
              :src="item.src"
              controls
              autoplay
              muted
              loop
              webkit-playsinline=""
              playsInline
              x5-playsinline
              t7-video-player-type="inline"
              x5-video-player-type="h5-page"
              x-webkit-airplay="allow"
              disablePictureInPicture
            ></video>
          </div>
          <div v-else>
            <img src="" alt="$t('NoImages')" />
          </div>

          <div class="carousel-text" v-if="item && item.routeTile">
            <h3>{{ item.routeTile }}</h3>
            <button
              class="custom-button"
              :style="buttonVars"
              @mouseover="isHighlighted = true"
              @mouseleave="isHighlighted = false"
              @click="handClick(item)"
            >
              {{ $t('ExploreMore') }}
            </button>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import { getRouteList } from '@/utils/api.js';
import Swiper from 'swiper';

export default {
  name: 'CarouselView',
  props: {
    // 传入的轮播图数据
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataList: [],
      isHighlighted: false,
      currentIndex: 0,
    };
  },
  computed: {
    // 获取当前轮播图的数据
    ImgList() {
      return this.list.length ? this.list : this.dataList;
    },
    // 按钮样式
    buttonVars() {
      const currentItem = this.dataList[this.currentIndex] || {};
      return {
        '--bg-color': this.isHighlighted
          ? currentItem.buttonColor || '#a1a782'
          : currentItem.textColor || '#fff',
        '--text-color': this.isHighlighted
          ? currentItem.textColor || '#fff'
          : currentItem.buttonColor || '#a1a782',
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.list.length < 1) {
        this.getRouteList();
      }
    });
  },
  methods: {
    init() {
      // 判断屏幕宽度
      const falge = window.innerWidth > 1200;

      const self = this;

      var video = document.querySelector('.sVideo');

      let swiper0 = new Swiper('.swiper-container', {
        // loop: true,
        mousewheel: false,
        keyboard: true,
        autoplay: {
          // 自动播放延迟时间，单位为毫秒
          delay: 3000,
          // 是否在用户交互后禁用自动播放
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        speed: 1000,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        followFinger: falge,
        on: {
          slideChange: function () {
            self.currentIndex = this.realIndex;
            swiper0.autoplay.start();
          },
          slideChangeTransitionStart: function () {
            //!现在多个视频有问题，视频放最后一个不会轮播
            if (video && self.dataList[this.activeIndex].type === 'video') {
              // 判断当前swiper索引，如果是第一个就停止自动轮播
              this.autoplay.stop();
              video.currentTime = 0.1;
              video.play();
            } else if (video) {
              video.currentTime = 0.1;
              video.pause();
            }
          },
        },
      });

      if (video) {
        swiper0.autoplay.stop(); // 初始化关闭自动轮播
        // 监听视频播放进度
        document.querySelector('.sVideo').ontimeupdate = function () {
          var duration = video.duration; // 视频总时长
          var currentTime = video.currentTime; // 当前播放时长
          // var videoEnd = duration.toString().slice(0, 2); // 截取视频总时长秒数
          // console.log(1111, duration, currentTime, videoEnd);

          // 如果当前播放时长小于总时长并且大于总时长秒数就开始自动轮播，这里之所以这么判断是因为duration 和currentTime 的小数位并不能保证完全一致，所以就根据当前播放时长的整数秒是否大于总时长的整数秒
          // if (currentTime < video.duration && currentTime > videoEnd) {
          //   swiper0.autoplay.start();
          // }
          if (duration - currentTime < 3) {
            swiper0.autoplay.start();
          }
        };
        video.play().catch(error => console.log('播放错误: ', error));
      }
    },
    handleVideoEnded() {
      const swiper = new Swiper('.swiper-container');
      swiper.slideNext();
      // 重新启动自动播放
      swiper.autoplay.start();
    },
    // 获取表头，幻灯片
    getRouteList() {
      // console.log("🚀 ~ getRouteList ~ val:", val ? '冠亚' : localStorage.getItem('brand'))
      let brand = localStorage.getItem('brand');

      if (brand && brand !== 'null') {
        //
      } else {
        brand = '冠亚首页';
      }

      const data = {
        routeType: '幻灯片',
        routeBrand: brand,
      };
      getRouteList(data).then(res => {
        this.dataList = res.data.pageItems.map(item => {
          if (item.seriesLogo && item.seriesLogo.length > 0) {
            const logo = item.seriesLogo[0];
            if (
              logo.endsWith('.jpg') ||
              logo.endsWith('.jpeg') ||
              logo.endsWith('.png') ||
              logo.endsWith('.gif')
            ) {
              return { ...item, type: 'image', src: logo };
            } else if (logo.endsWith('.mp4') || logo.endsWith('.mov') || logo.endsWith('.avi')) {
              return { ...item, type: 'video', src: logo };
            } else {
              return { ...item, type: 'unknown', src: logo };
            }
          } else {
            return null; // 或者返回一个默认值，或者抛出一个错误
          }
        });

        this.$nextTick(() => {
          this.init();
        });
      });
    },
    handClick(item) {
      if (item && item.routeUrl && item.routeUrl != '/') {
        this.$router.push(item.routeUrl);
      } else {
        this.$router.push('/sort');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 80vh; /* 跟下面高度保持一致*/
  @media (max-width: 768px) {
    height: 70vh;
  }
}

/* 确保图片占满容器并保持纵横比 */
.swiper-slide img,
.swiper-slide video {
  width: 100vw;
  height: 80vh;
  object-fit: cover; /* 使图片覆盖容器 */
  @media (max-width: 768px) {
    height: 70vh;
  }
}
.swiper-slide {
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  color: #127749;
}

// 自定义箭头样式
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 2vw; // 更改箭头图标大小
}

//swiper  左右滑动箭头 第一和最后一个自动隐藏
.swiper-button-disabled {
  opacity: 0 !important;
}

.swiperBox ::v-deep {
  margin-bottom: 2%;
  .swiper-pagination {
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    width: 20px; /* 默认宽度（未选中） */
    height: 4px;
    background: #ccc; /* 默认颜色 */
    opacity: 1;
    border-radius: 2px;
    transition: width 0.3s, background 0.3s; /* 添加过渡效果 */
  }

  .swiper-pagination-bullet-active {
    width: 50px; /* 选中时宽度 */
    background: #127749; /* 选中时颜色 */
  }

  // 只有一页的时候，不显示分页
  .swiper-pagination-bullet:only-child {
    display: none !important;
  }
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 1px 1px 2px black;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;

  .custom-button {
    margin-top: 20px;
    display: inline-block;
    padding: 10px 30px;
    border: none;
    border-radius: 50px; /* 椭圆形状 */
    cursor: pointer;
    border: 1px solid transparent;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
  }
}
</style>
